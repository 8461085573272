import {Component, input} from '@angular/core';

@Component({
  selector: 'app-spinner',
  standalone: true,
  template: `
    <div class="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center  bg-opacity-50 z-50">
      <div
        [class]="
          'animate-spin rounded-full border-2 border-gray-600 border-t-transparent ' +
          sizeClasses[size()] + ' ' +
          colorClasses[color()]
        "
      ></div>
    </div>
  `,
  styles: [`
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .animate-spin {
      animation: spin 1s linear infinite;
    }
  `]
})
export class SpinnerComponent {

  public size = input<'sm' | 'md' | 'lg'>('md');
  public color = input<'primary' | 'secondary' | 'accent'>('primary');

  readonly sizeClasses: Record<string, string> = {
    sm: 'w-8 h-8',
    md: 'w-16 h-16',
    lg: 'w-32 h-32'
  };

  readonly colorClasses: Record<string, string> = {
    primary: 'border-primary',
    secondary: 'border-secondary',
    accent: 'border-accent'
  };
}
